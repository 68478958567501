import React, { useState } from "react";
import { Drawer, IconButton, List, ListItemButton, ListItemText, ListItemIcon } from "@mui/material";
import Collapse from '@mui/material/Collapse';
import { Link } from 'gatsby';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import './header.scss';




// const pages = ["about us", "solutions", "insights", "why cartus", "get in touch", "technology"];
// const pageslinks = ["about-us", "Global-Landing", "ResourceHub", "why-cartus", "contact-us", "Technology-Landing"];
const DrawerComp = () => {
  const [openDrawer, setOpenDrawer] = useState(false);

  // sub-menu
  const [loginOpen, setloginOpen] = React.useState(false);
  const loginClick = () => {
    setloginOpen(!loginOpen);
  };

  const [langOpen, setlangOpen] = React.useState(false);
  const langClick = () => {
    setlangOpen(!langOpen);
  };

  const [blogOpen, setblogOpen] = React.useState(false);
  const blogClick = () => {
    setblogOpen(!blogOpen);
  };

  const [researchOpen, setresearchOpen] = React.useState(false);
  const researchClick = () => {
    setresearchOpen(!researchOpen);
  };

  const [podcastOpen, setpodcastOpen] = React.useState(false);
  const podcastClick = () => {
    setpodcastOpen(!podcastOpen);
  };

  const [pagesOpen, setpagesOpen] = React.useState(false);
  const pagesClick = () => {
    setpagesOpen(!pagesOpen);
  };

  return (
    <>
      <Drawer
        anchor="top"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        className="header-drawer-cls"
      >
        <div className='header-big-donut-image'>
          <div className='header-small-donut-image'>

             {/* Links Array Map */}
             <List className="normal-list">
                <ListItemButton>
                  <ListItemText>
                      <Link className="menu-item-mob" to={'/about-us/'} role="link">
                        about us
                      </Link>
                    </ListItemText>
                </ListItemButton>
                <ListItemButton>
                    <ListItemText>
                      <Link className="menu-item-mob" to={'/Global-Landing/'} role="link">
                        solutions
                      </Link>
                    </ListItemText>
                </ListItemButton>
                <ListItemButton>
                    <ListItemText>
                      <Link className="menu-item-mob" to={'/ResourceHub/'} role="link">
                        insights
                      </Link>
                    </ListItemText>
                </ListItemButton>
                <ListItemButton>
                    <ListItemText>
                      <Link className="menu-item-mob" to={'/why-cartus/'} role="link">
                        why cartus
                      </Link>
                    </ListItemText>
                </ListItemButton>
                <ListItemButton>
                    <ListItemText>
                      <Link className="menu-item-mob" to={'/contact-us/'} role="link">
                        get in touch
                      </Link>
                    </ListItemText>
                </ListItemButton>
                <ListItemButton>
                    <ListItemText>
                      <Link className="menu-item-mob" to={'/Technology-Landing/'} role="link">
                        technology
                      </Link>
                    </ListItemText>
                </ListItemButton>
                <ListItemButton>
                    <ListItemText>
                      <Link className="menu-item-mob" to={'/Careers/'} role="link">
                        careers
                      </Link>
                    </ListItemText>
                </ListItemButton>
            </List>

            {/* Dropdowns Menu */}
            <List className="dropdown-list">
              {/* login */}
              <ListItemButton onClick={loginClick}>
                <ListItemText primary="login" className="menu-item-mob" />
                {loginOpen ?
                  <ExpandMoreIcon className='expand-icons' /> :
                  <ChevronRightIcon className='expand-icons' />}
              </ListItemButton>
              <Collapse in={loginOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemText className="menu-item-mob-nor" primary="Cartus Online" />
                  </ListItemButton>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemText className="menu-item-mob-nor" primary="Cartus Online (United Kingdom)" />
                  </ListItemButton>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemText className="menu-item-mob-bold" primary="Relocation Resource Center" />
                  </ListItemButton>
                </List>
              </Collapse>

              {/* languages */}
              <ListItemButton onClick={langClick}>
                <ListItemText primary="en" className="menu-item-mob" />
                {langOpen ?
                  <ExpandMoreIcon className='expand-icons' /> :
                  <ChevronRightIcon className='expand-icons' />}
              </ListItemButton>
              <Collapse in={langOpen} timeout="auto" unmountOnExit>
                <List component="div">
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemText className="menu-item-mob-nor" primary="English" />
                  </ListItemButton>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemText className="menu-item-mob-bold" primary="French" />
                  </ListItemButton>
                </List>
              </Collapse>
            </List>          
          </div>
        </div>
      </Drawer>

      {/* Hamburger Icon */}
      <IconButton onClick={() => setOpenDrawer(!openDrawer)} className='hamburger-btn'>
        <MenuIcon className="hamburger-icon" />
      </IconButton>
    </>
  );
};

export default DrawerComp;
