import React, { useState } from "react";
import { createTheme } from '@material-ui/core/styles';
import { Link } from 'gatsby';
import { AppBar, Toolbar, useMediaQuery, useTheme, ListItemText, ListItem, List, Input } from "@material-ui/core";
import DrawerComp from "./Drawer";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import HeaderSearchIcon from '../../assets/header-search-icon.png';
import { Grid} from '@material-ui/core';
import './header.scss';
import Container from '@material-ui/core/Container';



const theme = createTheme({
});


const Header = () => {
    const [value, setValue] = useState();
    const theme = useTheme();
    console.log(theme);
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    console.log(isMobile);

    // dropdown open login     
    const [loginEl, setloginEl] = React.useState(null);
    const loginOpen = Boolean(loginEl);
    const loginClick = (event) => {
        setloginEl(event.currentTarget);
    };
    const loginClose = () => {
        setloginEl(null);
    };

    // dropdown open languages
    const [langEl, setlangEl] = React.useState(null);
    const langOpen = Boolean(langEl);
    const langClick = (event) => {
        setlangEl(event.currentTarget);
    };
    const langClose = () => {
        setlangEl(null);
    };

    // dropdown open blogs     
    const [blogEl, setblogEl] = React.useState(null);
    const blogOpen = Boolean(blogEl);
    const blogClick = (event) => {
        setblogEl(event.currentTarget);
    };
    const blogClose = () => {
        setblogEl(null);
    };

    // dropdown open research     
    const [researchEl, setresearchEl] = React.useState(null);
    const researchOpen = Boolean(researchEl);
    const researchClick = (event) => {
        setresearchEl(event.currentTarget);
    };
    const researchClose = () => {
        setresearchEl(null);
    };

    // dropdown open podcast     
    const [podcastEl, setpodcastEl] = React.useState(null);
    const podcastOpen = Boolean(podcastEl);
    const podcastClick = (event) => {
        setpodcastEl(event.currentTarget);
    };
    const podcastClose = () => {
        setpodcastEl(null);
    };

    // dropdown open Inner pages     
    const [pageEl, setpageEl] = React.useState(null);
    const pageOpen = Boolean(pageEl);
    const pageClick = (event) => {
        setpageEl(event.currentTarget);
    };
    const pageClose = () => {
        setpageEl(null);
    };

    // searchbar
    const [showSearch, setShowSearch] = useState(false);
    const searchBarShow = () => {
        setShowSearch(!showSearch)
    }

    return (
        <>

            <AppBar className="header-main">
                <Container maxWidth="xl" className="header-container-padding">
                    <Toolbar className='MuiToolbar-root'>
                        {/* Common Cartus Logo */}
                        <Link to={'/'} role="link" tabIndex="0">
                            <img src="//images.ctfassets.net/2czxzrwels61/5rvlcUMaNgluX60elNQ2xr/39778a8aeff5a99b6099551b5c837e05/logo.png"
                                alt="Cartus Logo" className="main-logo" />
                        </Link>

                        {isMobile ? (
                            // Mobile Structure Starts
                            <>
                                <div className="search-icon">
                                    {showSearch && (
                                        <div className="search-ip">
                                            <div className="search-bar-icons">
                                                <SearchIcon className="mui-search-icon" />
                                                <Input icon='search'
                                                    placeholder='search our site'
                                                    className="search-ip-border"
                                                />
                                                <CloseIcon className="search-cross-icon" />
                                            </div>
                                            <Button className="search-go-btn">Go</Button>
                                        </div>
                                    )}
                                    <Grid onClick={searchBarShow}>
                                    <img src={HeaderSearchIcon} alt="Search Icon Logo" className="search-icon-mob" />
                                    </Grid>
                                    {/* <Avatar className="search-img" onClick={searchBarShow}>
                                        <SearchIcon className='search-icon-circle' />
                                    </Avatar> */}
                                    <DrawerComp />
                                </div>
                            </>
                            // Mobile Structure Ends
                        ) : (

                            // Desktop Structure Starts
                            <>
                                {/* links */}
                                <div className="left-links-container">
                                    <List component="nav" className="left-nav-links">
                                        <ListItem button className="nav-list-items">
                                            <Link className="menu-items-nor" to={'/about-us/'} role="link" >
                                                <ListItemText primary="about us" className="navbar-links" />
                                            </Link>
                                        </ListItem>
                                        <ListItem button className="nav-list-items">
                                            <Link className="menu-items-nor" to={'/Global-Landing/'} role="link" >
                                                <ListItemText primary="solutions" className="navbar-links" />
                                            </Link>
                                        </ListItem>
                                        <ListItem button className="nav-list-items">
                                            <Link className="menu-items-nor" to={'/ResourceHub/'} role="link" >
                                                <ListItemText primary="insights" className="navbar-links" />
                                            </Link>
                                        </ListItem>
                                        <ListItem button className="nav-list-items">
                                            <Link className="menu-items-nor" to={'/Technology-Landing/'} role="link" >
                                                <ListItemText primary="technology" className="navbar-links" />
                                            </Link>
                                        </ListItem>
                                        <ListItem button className="nav-list-items">
                                            <Link className="menu-items-nor" to={'/why-cartus/'} role="link" >
                                                <ListItemText primary="why cartus" className="navbar-links" />
                                            </Link>
                                        </ListItem>
                                        <ListItem button className="nav-list-items">
                                            <Link className="menu-items-nor" to={'/contact-us/'} role="link" >
                                                <ListItemText primary="get in touch" className="navbar-links" />
                                            </Link>
                                        </ListItem>
                                        <ListItem button className="nav-list-items">
                                            <Link className="menu-items-nor" to={'/Careers/'} role="link" >
                                                <ListItemText primary="careers" className="navbar-links" />
                                            </Link>
                                        </ListItem>
                                        
                                    </List>
                                </div>

                                <div className="navbar-right-btns" >
                                    {/* logins */}
                                    <Button
                                        id="logins"
                                        aria-controls={loginOpen ? 'login-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={loginOpen ? 'true' : undefined}
                                        onClick={loginClick}
                                        className="navbar-btns"
                                    >
                                        <span className="dropdown-txt-bold">login</span>
                                        {loginOpen ?
                                            <ExpandLessIcon className="expand-icons-desk" />
                                            : <ExpandMoreIcon className="expand-icons-desk" />}
                                    </Button>
                                    <Menu
                                        id="login-menu"
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        anchorEl={loginEl}
                                        open={loginOpen}
                                        onClose={loginClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'logins',
                                        }}
                                    >
                                        <MenuItem onClick={loginClose} className="menu-items-nor">
                                            Cartus Online</MenuItem>
                                        <MenuItem onClick={loginClose} className="menu-items-nor">
                                            Cartus Online (United Kingdom)</MenuItem>
                                        <MenuItem onClick={loginClose} className="menu-items-bold">
                                            Relocation Resource Center</MenuItem>
                                    </Menu>
                                    {/* languages */}
                                    <Button
                                        id="languages"
                                        aria-controls={langOpen ? 'lang-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={langOpen ? 'true' : undefined}
                                        onClick={langClick}
                                        className="navbar-btns"
                                    >
                                        <span className="dropdown-txt-bold">en</span>
                                        {langOpen ?
                                            <ExpandLessIcon className="expand-icons-desk" />
                                            : <ExpandMoreIcon className="expand-icons-desk" />}
                                    </Button>
                                    <Menu
                                        id="lang-menu"
                                        anchorEl={langEl}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={langOpen}
                                        onClose={langClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'languages',
                                        }}
                                    >
                                        <MenuItem onClick={langClose} className="menu-items-nor">English</MenuItem>
                                        <MenuItem onClick={langClose} className="menu-items-bold">French</MenuItem>
                                    </Menu>
                                    
                                    <Button className="navbar-btns" onClick={searchBarShow}>
                                    <img src={HeaderSearchIcon} alt="Search Icon Logo" className="search-icon-des" />
                                    {/* <ListItemText primary="search" className="navbar-links" /> */}
                                    </Button>
                                   
                                    <div className="srch-bar-pos">
                                    {showSearch && (
                                        <div className="search-ip">
                                            <div className="search-bar-icons">
                                                <SearchIcon className="mui-search-icon" />
                                                <Input icon='search'
                                                    placeholder='search our site'
                                                    className="search-ip-border"
                                                />
                                                <CloseIcon className="search-cross-icon" />
                                            </div>
                                            <Button className="search-go-btn">Go</Button>
                                        </div>
                                    )}
                                </div>
                                </div>
                                {/* search bar */}
                                
                                
                            </>
                            // Desktop Structure Ends
                        )}
                    </Toolbar>
                </Container>

            </AppBar>


        </>
    );
};

export default Header;
