/* eslint-disable import/no-duplicates */
import { createTheme } from '@material-ui/core/styles';

export const customeTheme = () => createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 601,
      md: 901,
      lg: 1201,
      xl: 1313
    }
  }

});


