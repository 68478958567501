import React from 'react'
import Header from '../header'
import { customeTheme } from '../../Style';
import { ThemeProvider } from '@material-ui/core/styles';
import ThemeContext from '../../utils/theme-context';
import Footer from '../footer'
import '../../scss/typography.css'
export default function Layout({children}){
    
    return(
        
        <>
        
        <ThemeContext.Provider >
        <ThemeProvider theme={customeTheme()}>
          
          <main>
            {/* <Container className='wrapper'  maxWidth="xl" style={{ padding: '0px'}}>

            </Container> */}
            <Header/>
              {children}
            <Footer/>
          </main>
                 
        </ThemeProvider>
      </ThemeContext.Provider>
      
        </>
    )
}