import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Grid from '@material-ui/core/Grid';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import cartus_white_logo from '../../assets/cartus_white_logo.png';
import facebook from '../../assets/facebook.png';
import linkedin from '../../assets/linkedin.png';
import rss from '../../assets/rss.png';
import twitter from '../../assets/twitter.png';
import youtube from '../../assets/youtube.png';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoveToTop from "../../assets/movetotop.png";
import { createTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import capsule from '../../assets/capsules.png';
import dot_matrix from '../../assets/Dot matrix.png';
import plus_image from '../../assets/plus_get_touch.png';
import image_gettouch from '../../assets/image_get_touch.png';
import { Link } from 'gatsby';
import './footer.scss'
import Container from '@material-ui/core/Container';

export default function Footer() {
    const currentYear = (new Date().getFullYear());
    const [anchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const theme = createTheme({});
    // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isMobile = useMediaQuery('(max-width:1200px)');
    function scrollToTop(){
        window.scrollTo(0, 0);
    }

    const yearTxt = currentYear === 2022 ? "2022" : "2022 - " + currentYear;
    return (

        <Grid className='footer_cls'>
            <Grid container xl={12} lg={12} md={12} sm={12} xs={12} className="bottom-bar"></Grid>
            <div className='footer_big_donut_image'>
                <div className='footer_small_donut_image'>
                    <Container maxWidth="xl" className="footer-container-padding">
                    <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="goto-top-icon">
                    <img src={MoveToTop} onKeyPress={scrollToTop} onClick={scrollToTop} className="move-to-top" />
                    </Grid>
                    </Grid>
                        {(!isMobile)
                            ? (
                                <Grid container>
                                <Grid item lg={9}>
                                    <Grid container columns={12} className="footer-left-block">
                                        <Grid item className="item">
                                            <div className='title'><Link>about us</Link></div>
                                            <List className="sub-menu-align">
                                                <ListItem className="left-list-items"><a href='#'>Our Team</a></ListItem>
                                                <ListItem className="left-list-items"><a href='#'>Join Cartus</a></ListItem>
                                                <ListItem className="left-list-items"><a href='#'>Press Room</a></ListItem>
                                                <ListItem className="left-list-items"><Link to={'/Careers/'} role="link">Careers</Link></ListItem>
                                                <ListItem className="left-list-items"><Link to={'/SiteMap/'} role="link">Site Map</Link></ListItem>
                                            </List>
                                        </Grid>
                                        <Grid item className="item">
                                            <div className='title'><Link>solutions </Link></div>
                                            <List className="sub-menu-align">
                                                <ListItem className="left-list-items"><a href='#'>Blogs</a></ListItem>
                                                <ListItem className="left-list-items"><a href='#'>Events & Webinars</a></ListItem>
                                                <ListItem className="left-list-items"><a href='#'>Podcasts</a></ListItem>
                                                <ListItem className="left-list-items"><a href='#'>Research</a></ListItem>
                                                <ListItem className="left-list-items"><a href='#'>Whitepapers</a></ListItem>
                                                <ListItem className="left-list-items"><a href='#'>Videos</a></ListItem>
                                            </List>
                                        </Grid>
                                        <Grid item className="item">
                                            <div className='title'><Link>insights </Link></div>
                                            <List className="sub-menu-align">
                                                <ListItem className="left-list-items"><a href='#'>Movepro 360</a></ListItem>
                                                <ListItem className="left-list-items"><a href='#'>Moveazy</a></ListItem>
                                                <ListItem className="left-list-items"><a href='#'>DWS</a></ListItem>
                                                <ListItem className="left-list-items"><a href='#'>Benefitsbuilder</a></ListItem>
                                                <ListItem className="left-list-items"><a href='#'>Dummy Object</a></ListItem>
                                            </List>
                                        </Grid>
                                        <Grid item className="item">
                                            <div className='title'><Link>technology</Link>  </div>
                                            <List className="sub-menu-align">
                                                <ListItem className="left-list-items"><a href='#'>Global Products</a></ListItem>
                                                <ListItem className="left-list-items"><a href='#'>US/Canada</a></ListItem>
                                                <ListItem className="left-list-items"><a href='#'>Client</a></ListItem>
                                                <ListItem className="left-list-items"><a href='#'>Employees</a></ListItem>
                                                <ListItem className="left-list-items"><a href='#'>Service Category 1</a></ListItem>
                                                <ListItem className="left-list-items"><a href='#'>Service Category 2</a></ListItem>
                                            </List>
                                        </Grid>
                                        <Grid item className="item">
                                            <div className='title'><Link>why cartus</Link></div>
                                            <List className="sub-menu-align">
                                                <ListItem className="left-list-items"><a href='#'>DE & I</a></ListItem>
                                                <ListItem className="left-list-items"><a href='#'>Sustainability</a></ListItem>
                                                <ListItem className="left-list-items"><a href='#'>Insignia</a></ListItem>
                                                <ListItem className="left-list-items"><a href='#'>Technology</a></ListItem>
                                                <ListItem className="left-list-items"><a href='#'>Flexible Mobility</a></ListItem>
                                                <ListItem className="left-list-items"><a href='#'>Supply Chain</a></ListItem>
                                            </List>
                                        </Grid>

                                        
                                    </Grid>
                                    <Grid className="copyright-logo">
                                            <div className='footerline_img' >
                                                <img src={cartus_white_logo} />
                                            </div>
                                            <div className='footerline_copyright' >
                                                © {yearTxt} Cartus Corporation All rights reserved
                                            </div>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3} className="footer-right-block">
                                <Grid item className="item">
                                    <div className='title'>&nbsp;</div>
                                    <List className="sub-menu-align border-left">
                                        <ListItem className="left-list-items"><Link to={'/PrivacyNotice/'} role="link">Privacy Policy</Link></ListItem>
                                        <ListItem className="left-list-items"><Link to={'/TermsOfUse/'} role="link">Terms of Use</Link></ListItem>
                                        <ListItem className="left-list-items"><a href='#'>Accessibility</a></ListItem>
                                        <ListItem className="left-list-items"><a href='#'>Do not sell my info</a></ListItem>
                                        <ListItem className="left-list-items"><a href='#'>Modern Slavery Statement</a></ListItem>
                                        <ListItem className="left-list-items"><a href='#'>Cookie Preferences</a></ListItem>
                                    </List>
                                </Grid>
                                <Grid className="social-media-block">
                                    <div className='contactbtn_icon_button'>
                                            <Button variant="outlined" className='footerline_contact_button'>Contact us</Button>
                                    </div>
                                    <Grid className="social-icons-space">
                                        <div className='contactbtn_icons'>
                                        <a href="http://www.linkedin.com/company/cartus" locid="socialMediaIcons" target="_blank"><img className='footerline_icons' src={linkedin} /></a>
                                        <a href="http://twitter.com/Cartus" locid="socialMediaIcons" target="_blank"><img className='footerline_icons' src={twitter} /></a>
                                        <a href="https://www.facebook.com/pages/Cartus/235680599856342" locid="socialMediaIcons" target="_blank"><img className='footerline_icons' src={facebook} /></a>   
                                        <a href="http://www.youtube.com/user/CartusCorporation/featured" locid="socialMediaIcons" target="_blank"><img className='footerline_icons' src={youtube} /></a>    
                                        <a href="https://tst01.cartus.com/en/blog/rss.xml" locid="socialMediaIcons" target="_blank"><img className='footerline_icons' src={rss} /></a>
                                        </div>
                                    </Grid>
                                </Grid>    
                                </Grid>
                                </Grid>
                            )
                            : (
                                <div className="footer-mrgn-top">
                                    <Accordion className='footer_cls mobile_title accordion' >
                                        <AccordionSummary className="footer-arrow accordian-details" expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header"
                                        >
                                            <div className="mobile-menu">about us</div>
                                        </AccordionSummary>
                                        <AccordionDetails className="accordian-details">
                                            <div className="mobile-sub-menu-lists">
                                                <List className="mobile_sub_menu">
                                                    <ListItem><a href="#">Our Team</a></ListItem>
                                                    <ListItem><a href="#">Join Cartus</a></ListItem>
                                                    <ListItem><a href="#">Press Room</a></ListItem>
                                                    <ListItem><a href="#">Careers</a></ListItem>
                                                    <ListItem><Link to={'/SiteMap/'} role="link">Site Map</Link></ListItem>
                                                </List>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='footer_cls mobile_title'>
                                        <AccordionSummary className="footer-arrow accordian-details" expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header"
                                        >
                                        <div className="mobile-menu">solutions</div>
                                        </AccordionSummary>
                                        <AccordionDetails className="accordian-details">
                                            <div className="mobile-sub-menu-lists">
                                                <List className="mobile_sub_menu">
                                                    <ListItem><a href='#'>Blogs</a></ListItem>
                                                    <ListItem><a href='#'>Events & Webinars</a></ListItem>
                                                    <ListItem><a href='#'>Podcasts</a></ListItem>
                                                    <ListItem><a href='#'>Research</a></ListItem>
                                                    <ListItem><a href='#'>Whitepapers</a></ListItem>
                                                    <ListItem><a href='#'>Videos</a></ListItem>
                                                </List>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='footer_cls mobile_title'>
                                        <AccordionSummary className="footer-arrow accordian-details" expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header"
                                        >
                                            <div className="mobile-menu">insights</div>
                                        </AccordionSummary>
                                        <AccordionDetails className="accordian-details">
                                            <div className="mobile-sub-menu-lists">
                                                <List className="mobile_sub_menu">
                                                    <ListItem><a href='#'>Movepro 360</a></ListItem>
                                                    <ListItem><a href='#'>Moveazy</a></ListItem>
                                                    <ListItem><a href='#'>DWS</a></ListItem>
                                                    <ListItem><a href='#'>Benefitsbuilder</a></ListItem>
                                                    <ListItem><a href='#'>Dummy Object</a></ListItem>
                                                </List>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='footer_cls mobile_title'>
                                        <AccordionSummary className="footer-arrow accordian-details" expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header"
                                        >
                                            <div className="mobile-menu">technology</div>
                                        </AccordionSummary>
                                        <AccordionDetails className="accordian-details">
                                            <div className="mobile-sub-menu-lists">
                                                <List className="mobile_sub_menu">
                                                    <ListItem><a href='#'>Global Products</a></ListItem>
                                                    <ListItem><a href='#'>US/Canada</a></ListItem>
                                                    <ListItem><a href='#'>Client</a></ListItem>
                                                    <ListItem><a href='#'>Employees</a></ListItem>
                                                    <ListItem><a href='#'>Service Category 1</a></ListItem>
                                                    <ListItem><a href='#'>Service Category 2</a></ListItem>
                                                </List>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='footer_cls mobile_title'>
                                        <AccordionSummary className="footer-arrow accordian-details" expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header"
                                        >
                                            <div className="mobile-menu">why cartus</div>
                                        </AccordionSummary>
                                        <AccordionDetails className="accordian-details">
                                            <div className="mobile-sub-menu-lists">
                                                <List className="mobile_sub_menu">
                                                    <ListItem><a href='#'>DE & I</a></ListItem>
                                                    <ListItem><a href='#'>Sustainability</a></ListItem>
                                                    <ListItem><a href='#'>Insignia</a></ListItem>
                                                    <ListItem><a href='#'>Technology</a></ListItem>
                                                    <ListItem><a href='#'>Flexible Mobility</a></ListItem>
                                                    <ListItem><a href='#'>Supply Chain</a></ListItem>
                                                </List>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                    <hr className='hr_mobile_line'></hr>
                                        <Grid container className='mobile_title_privacy'>
                                            <List>
                                                <ListItem className="left-list-items"><Link to={'/PrivacyNotice/'} role="link">PRIVACY POLICY</Link></ListItem>
                                                <ListItem className="left-list-items"><Link to={'/TermsOfUse/'} role="link">TERMS OF USE</Link></ListItem>
                                                <ListItem className="left-list-items"><a href='#'>ACCESSIBILITY</a></ListItem>
                                                <ListItem className="left-list-items"><a href='#'>DO NOT SELL BY INFO</a></ListItem>
                                                <ListItem className="left-list-items"><a href='#'>MODERN SLAVERY STATEMENT</a></ListItem>
                                                <ListItem className="left-list-items"><a href='#'>COOKIE PREFERENCE</a></ListItem>
                                            </List>
                                        </Grid>
                                        <Grid className="social-media-block">
                                            <div className='contactbtn_icon_button'>
                                                    <Button variant="outlined" className='footerline_contact_button'>Contact us</Button>
                                            </div>
                                            <Grid className="social-icons-space">
                                            <div className='contactbtn_icons'>
                                            <a href="http://www.linkedin.com/company/cartus" locid="socialMediaIcons" target="_blank"><img className='footerline_icons' src={linkedin} /></a>
                                            <a href="http://twitter.com/Cartus" locid="socialMediaIcons" target="_blank"><img className='footerline_icons' src={twitter} /></a>
                                            <a href="https://www.facebook.com/pages/Cartus/235680599856342" locid="socialMediaIcons" target="_blank"><img className='footerline_icons' src={facebook} /></a>   
                                            <a href="http://www.youtube.com/user/CartusCorporation/featured" locid="socialMediaIcons" target="_blank"><img className='footerline_icons' src={youtube} /></a>    
                                            <a href="https://tst01.cartus.com/en/blog/rss.xml" locid="socialMediaIcons" target="_blank"><img className='footerline_icons' src={rss} /></a>
                                            </div>
                                            </Grid>
                                        </Grid>  
                                        <Grid className="copyright-logo">
                                            <div className='footerline_img' >
                                                <img src={cartus_white_logo} />
                                            </div>
                                            <div className='footerline_copyright' >
                                                © {yearTxt} Cartus Corporation All rights reserved
                                            </div>
                                        </Grid>  
                                </div>
                            )}
                    </Container>
                </div>

            </div>



        </Grid>





    )
}